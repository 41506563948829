import { useNavigate } from 'react-router';
import { useCallback } from 'react';
import { Button, ButtonColor } from '../Button';
import { ROUTES } from '../../lib/ROUTES';
import { Avatar } from '../Avatar';
import { getDisplayedName } from '../../lib/getDisplayedName';

interface CommentAuthorProps {
  author?: {
    id: string;
    nickname?: string | null;
    alias?: string | null;
    avatar?: {
      originalUrl: string;
      cropSettings?: unknown | null;
    } | null;
  };
  onClick?: () => void;
}

const CommentAuthor = ({ author, onClick }: CommentAuthorProps) => {
  const navigate = useNavigate();
  const displayName = author ? getDisplayedName(author.nickname, author.alias) : 'Spletnik';

  const onInnerClick = useCallback(async () => {
    onClick?.();
    if (author) {
      await navigate(ROUTES.USER_FILTER.$buildPath({ params: { id: author.alias || author.id } }));
    }
  }, [author, navigate, onClick]);

  return (
    <div className="grid grid-flow-col">
      <Button className="min-w-0" color={ButtonColor.Tertiary} onClick={onInnerClick}>
        <span className="truncate">{displayName}</span>
      </Button>
      <Avatar
        className="h-6 w-6 shrink-0 text-[0.75rem] md:h-8 md:w-8 md:text-[1.125rem]"
        image={author?.avatar}
        name={displayName}
      />
    </div>
  );
};

export { CommentAuthor };
