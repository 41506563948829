declare global {
  interface Window {
    ym?: {
      (id: number, type: 'hit', url: string, data?: { title?: string; referer?: string }): void;
      (id: number, type: 'reachGoal', goalName: string, params?: Record<string, unknown>): void;
    };
  }
}

function sendPageViewToMetrika() {
  if (PUBLIC_CONFIG.METRIKA_ID) {
    const url = `${window.location.pathname}${window.location.search}${window.location.hash}`;

    window.ym?.(PUBLIC_CONFIG.METRIKA_ID, 'hit', url, {
      title: document.title,
    });
  }
}

function sendMetrikaGoal(goalName: string) {
  if (PUBLIC_CONFIG.METRIKA_ID) {
    window.ym?.(PUBLIC_CONFIG.METRIKA_ID, 'reachGoal', goalName);
  }
}

export { sendMetrikaGoal, sendPageViewToMetrika };
