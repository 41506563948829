import { assertIsDefined } from '@flame-frontend-utils/commons';
import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { ReactToCommentDocument } from './gql/ReactToComment.document';
import { useLoginInfo } from '../../../../react-hooks/useLoginInfo';
import { useOnLoginFormOpen } from '../../../LoginFormProvider';
import { Reaction } from '../../../../lib/graphql.document';
import { MediaStatsActionable } from '../../../MediaStats';
import { UniversalComment } from '../../types';
import { tw } from '../../../../styles/tw';
import { ignoreReject } from '../../../../lib/ignoreReject';

interface CommentMediaStatsProps {
  comment: UniversalComment;
  className?: string;
}

const CommentMediaStats = ({ comment, className }: CommentMediaStatsProps) => {
  const [react] = useMutation(ReactToCommentDocument);
  const {
    id,
    reactions: { likesCount, dislikesCount, myReaction },
  } = comment;

  const { data: userData } = useLoginInfo();
  const onLoginFormOpen = useOnLoginFormOpen();

  const onReact = useCallback(
    (reaction: Reaction) => {
      assertIsDefined(userData);

      if (userData.loginInfo.user) {
        void ignoreReject(react({ variables: { id, reaction } }));
      } else {
        onLoginFormOpen();
      }
    },
    [id, onLoginFormOpen, react, userData],
  );

  return (
    <MediaStatsActionable
      className={tw('ml-auto md:ml-0', className)}
      isComment
      likes={likesCount}
      dislikes={dislikesCount}
      personalReaction={myReaction}
      onReact={onReact}
      disabled={!userData}
    />
  );
};

export { CommentMediaStats };
