import { ButtonVariant } from './types';
import { tw } from '../../styles/tw';

/** @deprecated Use buttonBorderRadius instead */
const DEFAULT_BUTTON_BORDER_RADIUS = 24; // px

const buttonBorderRadius = tw('rounded-full');

const commonStyles = tw('px-5 py-2 leading-6', buttonBorderRadius);

const fixDisabledState = tw('disabled:pointer-events-none');

const buttonTransition = tw('transition-colors duration-300 ease-in-out active:transition-none');

const outlined = tw(
  'border border-solid border-[var(--accent-color)] text-[var(--accent-color)] hover:bg-[var(--accent-color)] hover:text-[var(--base-color)] active:bg-[var(--accent-color)] active:text-[var(--base-color)] disabled:opacity-50',
  commonStyles,
  buttonTransition,
  fixDisabledState,
);

const contained = tw(
  'border border-solid border-[var(--accent-color)] bg-[var(--accent-color)] text-[var(--base-color)] hover:bg-[var(--base-color)] hover:text-[var(--accent-color)] active:bg-[var(--base-color)] active:text-[var(--accent-color)] disabled:opacity-50',
  commonStyles,
  buttonTransition,
  fixDisabledState,
);

const text = tw(
  'flex p-0 text-[var(--accent-color)] decoration-1 underline-offset-4 hover:underline active:underline [@media(hover:hover){&:hover_svg}]:text-[var(--muted-color)]',
  buttonTransition,
  fixDisabledState,
);

const underlined = tw(
  'relative flex p-0 text-[var(--accent-color)] after:absolute after:bottom-0.5 after:hidden after:h-px after:w-full after:bg-[var(--accent-color)] hover:after:block active:after:block',
  buttonTransition,
  fixDisabledState,
);

const inlineText = tw(
  'inline p-0 text-[var(--accent-color)] underline decoration-1 underline-offset-4 hover:text-[var(--muted-color)]',
  buttonTransition,
  fixDisabledState,
);

function getVariantStyles(variant: ButtonVariant): string {
  switch (variant) {
    case ButtonVariant.Contained:
      return contained;
    case ButtonVariant.Outlined:
      return outlined;
    case ButtonVariant.InlineText:
      return inlineText;
    case ButtonVariant.Underlined:
      return underlined;
    case ButtonVariant.Text:
    default:
      return text;
  }
}

export { getVariantStyles, DEFAULT_BUTTON_BORDER_RADIUS, buttonBorderRadius };
