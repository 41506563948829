import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { sendPageViewToMetrika } from '../../lib/yandexMetrika';

const MetrikaPageViewReporter = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    sendPageViewToMetrika();
  }, [pathname]);

  return null;
};

export { MetrikaPageViewReporter, sendPageViewToMetrika };
