import { isDefined } from '@flame-frontend-utils/commons';
import { CropSettings } from './types';

interface ResizeOptions extends ResizeParams {
  originalUrl: string;
  resizeOrigin?: string;
}

interface ResizeParams {
  cropSettings?: unknown;
  dpr?: 1 | 2;
  width?: number;
  height?: number;
  fit?: 'cover' | 'contain';
}

function getResizedImageSrcSet(opts: Omit<ResizeOptions, 'dpr'>): string {
  return `${getResizedImageUrl(opts)}, ${getResizedImageUrl({ ...opts, dpr: 2 })} 2x`;
}

function getResizedImageUrl(opts: ResizeOptions) {
  return `${opts.resizeOrigin ?? ''}/resize/${getResizedUrlParams(opts)}/${opts.originalUrl}`;
}

function getResizedUrlParams({ cropSettings, dpr, height, width, fit }: ResizeParams): string {
  let trim: string | undefined;

  let resultingFit = fit ?? 'contain';

  if (isCropSettings(cropSettings)) {
    const trimTop = cropSettings.y;
    const trimRight = Math.max(
      0,
      cropSettings.originalWidth - (cropSettings.x + cropSettings.width / cropSettings.scale),
    );
    const trimBottom = Math.max(
      0,
      cropSettings.originalHeight - (cropSettings.y + cropSettings.height / cropSettings.scale),
    );
    const trimLeft = cropSettings.x;

    const dprCorrection = dpr ?? 1;

    trim = `${Math.floor(trimTop / dprCorrection)};${Math.floor(trimRight / dprCorrection)};${Math.floor(
      trimBottom / dprCorrection,
    )};${Math.floor(trimLeft / dprCorrection)}`;

    if (trim === '0;0;0;0') {
      trim = undefined;
    }

    resultingFit = 'cover';
  }

  const params = {
    fit: resultingFit,
    gravity: '0.5x0.5',
    format: 'auto',
    width: isDefined(width) ? Math.floor(width) : undefined,
    height: isDefined(height) ? Math.floor(height) : undefined,
    trim,
    dpr,
  };

  return Object.entries(params)
    .filter(([, value]) => isDefined(value))
    .map(([key, value]) => `${key}=${value ?? ''}`)
    .join(',');
}

function isCropSettings(settings: unknown): settings is CropSettings {
  return (
    isRecord(settings) &&
    ['x', 'y', 'width', 'height', 'scale', 'originalWidth', 'originalHeight'].every(
      (prop) => typeof settings[prop] === 'number',
    )
  );
}

function isRecord(settings: unknown): settings is Record<string, unknown> {
  return Boolean(settings && typeof settings === 'object');
}

export { getResizedImageUrl, getResizedImageSrcSet };
