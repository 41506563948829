import { ServerError, ServerParseError, ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

function everyGqlErrorIs<T>(...types: T[]) {
  return (error: unknown): boolean =>
    hasGraphqlErrors(error) &&
    getGqlErrors(error).every((graphqlError) => types.includes(graphqlError.extensions.code as T));
}

function someGqlErrorIs<T>(...types: T[]) {
  return (error: unknown): boolean =>
    hasGraphqlErrors(error) &&
    getGqlErrors(error).some((graphqlError) => types.includes(graphqlError.extensions.code as T));
}

function isNetworkError(error: unknown): error is { networkError: Error | ServerError | ServerParseError } {
  return hasNetworkError(error) && !hasGraphqlErrors(error);
}

function getGqlErrors(error: unknown): GraphQLError[] {
  const result: GraphQLError[] = [];

  if (hasPopulatedGraphqlErrors(error)) {
    result.push(...error.graphQLErrors);
  }

  if (hasUnpopulatedGraphqlErrors(error)) {
    result.push(...error.networkError.result.errors);
  }

  return result;
}

function hasGraphqlErrors(error: unknown): boolean {
  return hasPopulatedGraphqlErrors(error) || hasUnpopulatedGraphqlErrors(error);
}

function hasPopulatedGraphqlErrors(error: unknown): error is { graphQLErrors: ReadonlyArray<GraphQLError> } {
  return Boolean(
    error &&
      typeof error === 'object' &&
      Array.isArray((error as Partial<ApolloError>).graphQLErrors) &&
      (error as ApolloError).graphQLErrors.length,
  );
}

// https://github.com/apollographql/apollo-client/issues/6222
function hasUnpopulatedGraphqlErrors(
  error: unknown,
): error is { networkError: { result: { errors: ReadonlyArray<GraphQLError> } } } {
  return Boolean(
    hasNetworkError(error) &&
      'result' in error.networkError &&
      typeof error.networkError.result !== 'string' &&
      Array.isArray(error.networkError.result.errors) &&
      error.networkError.result.errors.length,
  );
}

function hasNetworkError(error: unknown): error is { networkError: Error | ServerError | ServerParseError } {
  return Boolean(error && typeof error === 'object' && (error as ApolloError).networkError);
}

export { getGqlErrors, everyGqlErrorIs, someGqlErrorIs, isNetworkError };
