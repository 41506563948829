import { useQuery, useSubscription } from '@apollo/client';
import { memo } from 'react';
import Notifications from './icons/notifications.svg?svgr';
import { Button } from '../Button';
import { NotificationDocument } from './gql/Notification.document';
import { NotificationsDocument } from './NotificationsMenu/gql/Notifications.document';
import { User } from '../../react-hooks/useLoginInfo';

interface NotificationIconProps {
  user: NonNullable<User>;
  onClick: (hasUnviewed: boolean) => void;
}

const NotificationIcon = memo(({ user, onClick }: NotificationIconProps) => {
  const { data } = useQuery(NotificationsDocument, {
    variables: { isViewed: false },
  });

  useSubscription(NotificationDocument, {
    variables: { userId: user.id },
    onData: ({ client, data: subscriptionData }) => {
      const unviewedNotificationQuery = client.readQuery({
        query: NotificationsDocument,
        variables: { isViewed: false },
      });
      const viewedNotificationQuery = client.readQuery({ query: NotificationsDocument });
      const newEdge = subscriptionData.data?.notificationReceived;

      if (
        unviewedNotificationQuery &&
        newEdge &&
        !unviewedNotificationQuery.notifications.edges.some(({ cursor }) => cursor === newEdge.cursor)
      ) {
        client.writeQuery({
          query: NotificationsDocument,
          variables: { isViewed: false },
          data: {
            notifications: {
              ...unviewedNotificationQuery.notifications,
              edges: [newEdge, ...unviewedNotificationQuery.notifications.edges],
              totalCount: unviewedNotificationQuery.notifications.totalCount + 1,
            },
          },
        });
      }

      if (
        viewedNotificationQuery &&
        newEdge &&
        !viewedNotificationQuery.notifications.edges.some(({ cursor }) => cursor === newEdge.cursor)
      ) {
        client.writeQuery({
          query: NotificationsDocument,
          variables: {},
          data: {
            notifications: {
              ...viewedNotificationQuery.notifications,
              edges: [newEdge, ...viewedNotificationQuery.notifications.edges],
              totalCount: viewedNotificationQuery.notifications.totalCount + 1,
            },
          },
        });
      }
    },
  });

  const counter = data?.notifications.totalCount || 0;

  return (
    <Button className="relative" onClick={() => onClick(counter > 0)}>
      <Notifications />
      {counter > 0 ? (
        <div className="absolute -right-1 -top-1 flex h-4 min-w-[0.25rem] items-center justify-center rounded-full bg-blood p-1 text-cream [font-size:0.75rem] [line-height:1.375rem]">
          {counter}
        </div>
      ) : null}
    </Button>
  );
});

export { NotificationIcon };
