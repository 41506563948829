import { useTypedParams } from 'react-router-typesafe-routes';
import { Navigate } from '@flame-frontend-utils/commons-router6';
import { ROUTES } from '../lib/ROUTES';

interface NavigateFromLegacyTagsProps {
  children: JSX.Element;
}

const LEGACY_SECTIONS: Record<string, Record<string, unknown>> = {
  buzz: {
    chronicle: {},
    gossip: {},
    baby: {},
    love: {},
    showbiz: {},
    monarchy: {},
    pressa: {},
    calendar: {},
    interview1: {},
    charity: {},
  },
  look: {
    newsmoda: {},
    starstyle: {},
    redcarpet: {},
    battle: {},
    fashionadvert: {},
    trends: {},
    editorial: {},
    lookweek: {},
    interview: {},
    lookbook: {},
  },
  beauty: {
    news_beauty: {},
    beautytrends: {},
    beautyexpert: {},
    transform: {},
    beautylook: {},
    exterior: {},
    beautyinterview: {},
    'na-lichnom-opyte': {},
    'zvezdnye-diety-i-fitnes': {},
    beautyevolution: {},
  },
  culture: {
    cinema: {},
    serialy: {},
    media: {},
    afisha: {},
    tech: {},
  },
  events: {},
  blogs: {
    blog_redakcii_spletnika: {},
    pro_zvezd: {},
    govoryat_chto: {},
    moda: {},
    krasota: {},
    kruto: {},
    pro_kino: {},
    chto_chitaem: {},
    vokrug_sveta: {},
    den_rozhdeniya_spletnika: {},
    konkursy_i_oprosy: {},
    muzyka: {},
    'sport-i-sportsmeny': {},
  },
};

const NavigateFromLegacyTags = ({ children }: NavigateFromLegacyTagsProps) => {
  const { section, subsection, slug } = useTypedParams(ROUTES.POST);

  if (!section || !PUBLIC_CONFIG.PUBLIC_PATH.startsWith(`/${section}/`)) {
    if (!subsection && section && slug && LEGACY_SECTIONS[section]?.[slug]) {
      return <Navigate to={ROUTES.TAG_FILTER.$buildPath({ params: { id: slug } })} replace statusCode={301} />;
    }

    if (!section && !subsection && slug && LEGACY_SECTIONS[slug]) {
      return <Navigate to={ROUTES.TAG_FILTER.$buildPath({ params: { id: slug } })} replace statusCode={301} />;
    }
  }

  return children;
};

export { NavigateFromLegacyTags };
