import { Button, ButtonColor } from '../../Button';
import { ROUTES } from '../../../lib/ROUTES';
import { ThemeSwitcher } from '../ThemeSwitcher';
import { AgeRestrictionPlaque } from '../../AgeRestrictionPlaque';

interface FormalitiesProps {
  onClick: () => void;
}

const BottomSection = ({ onClick }: FormalitiesProps) => (
  <div className="mt-auto flex flex-col items-start gap-0.5 text-sm">
    <AgeRestrictionPlaque className="mb-3.5" />
    <ThemeSwitcher />
    <Button color={ButtonColor.Secondary} to={ROUTES.ABOUT.$buildPath({})} onClick={onClick}>
      О нас
    </Button>
    <Button color={ButtonColor.Secondary} to={ROUTES.ADVERTISING.$buildPath({})} onClick={onClick}>
      Реклама
    </Button>
  </div>
);

export { BottomSection };
