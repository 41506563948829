import {
  ColorScheme,
  ColorSchemeVariant,
  useColorScheme,
  useSetColorScheme,
  useIsClientSide,
} from '@flame-frontend-utils/commons';
import { useMutation } from '@apollo/client';
import { Switch } from '../Switch';
import { ChangeThemeDocument } from './gql/ChangeTheme.document';
import { Theme } from '../../lib/graphql.document';
import { ignoreReject } from '../../lib/ignoreReject';

const ThemeSwitcher = () => {
  const [, colorVariant] = useColorScheme();
  const setColorScheme = useSetColorScheme();
  const [changeTheme, { loading }] = useMutation(ChangeThemeDocument);

  const isClientSide = useIsClientSide();

  const onColorVariantSwitch = async (isSecondary: boolean) => {
    const response = await ignoreReject(
      changeTheme({
        variables: { dto: { theme: isSecondary ? Theme.Secondary : Theme.Primary } },
      }),
    );

    if (response) {
      const receivedTheme = response.data?.updateSettings.theme;
      setColorScheme(
        ColorScheme.Light,
        receivedTheme === Theme.Primary ? ColorSchemeVariant.Primary : ColorSchemeVariant.Secondary,
      );
    }
  };

  return isClientSide ? (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className="flex w-full cursor-pointer items-center justify-between">
      Черно-белая тема
      <Switch
        value={colorVariant === ColorSchemeVariant.Secondary}
        onChange={onColorVariantSwitch}
        disabled={loading || !colorVariant}
      />
    </label>
  ) : null;
};

export { ThemeSwitcher };
