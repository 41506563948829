import { createContext, ReactNode, useCallback, useContext, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useOpenState } from '@flame-frontend-utils/commons';
import { useApolloClient } from '@apollo/client';
import { dismissFloatingAds } from '../lib/dismissFloatingAds';
import { Sidebar } from './Sidebar';
import { Authentication } from './Authentication';
import { AuthDispatcher } from '../lib/authDispatcher';
import { tw } from '../styles/tw';

interface LoginFormProviderProps {
  children?: ReactNode;
}

interface LoginFormOpenOptions {
  onLoginFormClose?: () => void;
}

const LoginFormProvider = ({ children }: LoginFormProviderProps) => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const auth = new URLSearchParams(search).get('auth');

  const [isOpen, onOpen, onClose] = useOpenState();

  const closeActionRef = useRef<() => void>();

  const handleOpen = useCallback(
    ({ onLoginFormClose }: LoginFormOpenOptions = {}) => {
      closeActionRef.current = onLoginFormClose;
      onOpen();
    },
    [onOpen],
  );

  const removeAuthFromUrl = useCallback(async () => {
    if (auth === 'signup') {
      const searchParams = new URLSearchParams(search);
      searchParams.delete('auth');
      await navigate({ search: searchParams.toString() }, { replace: true });
    }
  }, [search, navigate, auth]);

  const handleClose = useCallback(async () => {
    closeActionRef.current?.();
    closeActionRef.current = undefined;

    await removeAuthFromUrl();

    onClose();
  }, [onClose, removeAuthFromUrl]);

  const client = useApolloClient();

  useEffect(() => {
    const unsubscribe = AuthDispatcher.getInstance().subscribe(async () => {
      await client.resetStore();

      handleOpen();
    });

    return () => {
      unsubscribe();
    };
  }, [client, handleOpen]);

  useEffect(() => {
    if (isOpen) {
      dismissFloatingAds();
    }

    if (auth === 'signup') {
      handleOpen();
    }
  }, [isOpen, auth, handleOpen]);

  return (
    <OnLoginFormOpenContext.Provider value={handleOpen}>
      <>
        {children}
        <Sidebar overlayClassName={tw('bg-overlay/30')} isOpen={isOpen} onRequestClose={handleClose} isSubModal>
          <section className="flex grow items-center justify-center bg-cream px-8 py-5 md:w-[33.875rem]">
            <Authentication onClose={handleClose} />
          </section>
        </Sidebar>
      </>
    </OnLoginFormOpenContext.Provider>
  );
};

const OnLoginFormOpenContext = createContext<(options?: LoginFormOpenOptions) => void>(() => {});

const useOnLoginFormOpen = () => useContext(OnLoginFormOpenContext);

export { LoginFormProvider, useOnLoginFormOpen };
