import { getDefined } from '@flame-frontend-utils/commons';
import { Button, ButtonColor } from '../../Button';
import { UserPermission } from '../../../lib/graphql.document';
import { ROUTES } from '../../../lib/ROUTES';
import { TagsMenu } from '../TagsMenu';
import { useLoginInfo } from '../../../react-hooks/useLoginInfo';
import { useOnLoginFormOpen } from '../../LoginFormProvider';
import { hasPermission } from '../../../lib/apollo';

interface MainSectionProps {
  closeMenu: () => void;
}

const MainSection = ({ closeMenu }: MainSectionProps) => {
  const { data: userData } = useLoginInfo();
  const onLoginFormOpen = useOnLoginFormOpen();

  return (
    <div className="flex flex-col items-start gap-1.5">
      <TagsMenu
        color={ButtonColor.Secondary}
        onClick={() => {
          closeMenu();
        }}
      />
      <div className="mt-4 flex flex-col items-start gap-1.5 text-xl">
        {userData ? (
          userData.loginInfo.user ? (
            <>
              <Button
                color={ButtonColor.Secondary}
                to={ROUTES.USER_FILTER.$buildPath({
                  params: {
                    id: userData.loginInfo.user.alias || getDefined(userData.loginInfo.user.id),
                  },
                })}
                onClick={closeMenu}
              >
                Мое
              </Button>
              {hasPermission(userData.loginInfo.user.permissions, UserPermission.WriteArticle) ? (
                <Button color={ButtonColor.Secondary} to={PUBLIC_CONFIG.CMS_HOST}>
                  CMS
                </Button>
              ) : null}
            </>
          ) : (
            <Button color={ButtonColor.Secondary} onClick={() => onLoginFormOpen()}>
              Мое
            </Button>
          )
        ) : null}
      </div>
    </div>
  );
};

export { MainSection };
