import 'core-js';
import '../meta/config/public/client';
import '../meta/global-types';
import '../meta/publicPath';
import './sentryInit';
import { hydrateRoot } from 'react-dom/client';
import { createElement } from 'react';
import { loadableReady } from '@loadable/component';
import { assertIsDefined } from '@flame-frontend-utils/commons';
import { BrowserRouter } from 'react-router';
import { App, getApolloClient } from '../App';

void loadableReady(() => {
  const root = document.getElementById('root');
  assertIsDefined(root);

  hydrateRoot(
    root,
    createElement(
      BrowserRouter,
      {},
      createElement(App, { client: getApolloClient(), serverData: { ...window.SERVER_DATA } }),
    ),
  );
});
