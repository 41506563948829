export enum AdvertisementId {
  Head = 'head',
  DesktopTopBillboard = 'desktop_top_billboard',
  DesktopMainTopBillboard = 'desktop_main_top_billboard',
  DesktopMainPageContentBillboard = 'desktop_main_page_content_billboard',
  DesktopContentSideBanner = 'desktop_content_side_banner',
  DesktopInContentBanner = 'desktop_in_content_banner',
  DesktopCommentsSideBanner = 'desktop_comments_side_banner',
  DesktopRecommendationsSideBanner = 'desktop_recommendations_side_banner',
  DesktopInCommentsBanner = 'desktop_in_comments_banner',
  DesktopInChildCommentsBanner = 'desktop_in_child_comments_banner',
  MobileTopBillboard = 'mobile_top_billboard',
  MobileMainTopBillboard = 'mobile_main_top_billboard',
  MobileMainPageAfterCover = 'mobile_main_page_after_cover',
  MobileMainPageInContent = 'mobile_main_page_in_content',
  MobileInContent = 'mobile_in_content',
  MobileAfterCover = 'mobile_after_cover',
  MobileInComments = 'mobile_in_comments',
  MobileInChildComments = 'mobile_in_child_comments',
  DesktopMainSideBanner = 'desktop_main_side_banner',
  DesktopTagSectionSideBanner = 'desktop_tag_section_side_banner',
}
