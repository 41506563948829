import { ReactNode, createContext, useContext, useMemo } from 'react';

interface AdvertisementDisabledProviderProps {
  children: ReactNode;
  disabled: boolean;
  disabledAds: string[];
}

const AdvertisementDisabledProvider = ({ children, disabled, disabledAds }: AdvertisementDisabledProviderProps) => {
  const ctx = useMemo(
    () => ({
      disabled,
      disabledAds,
    }),
    [disabled, disabledAds],
  );

  return <AdvertisementDisabledContext.Provider value={ctx}>{children}</AdvertisementDisabledContext.Provider>;
};

const AdvertisementDisabledContext = createContext<{ disabled: boolean; disabledAds: string[] }>({
  disabled: false,
  disabledAds: [],
});

const useAdvertisementDisabled = (id: string) => {
  const ctx = useContext(AdvertisementDisabledContext);

  return ctx.disabled || ctx.disabledAds.includes(id);
};

export { AdvertisementDisabledProvider, useAdvertisementDisabled };
