import { useMatch } from 'react-router';
import { Button, ButtonVariant } from '../Button';
import Magnifier from './icons/magnifier.svg?svgr';
import Burger from './icons/burger.svg?svgr';
import { ROUTES } from '../../lib/ROUTES';
import { CreatePostButton } from '../CreatePostButton';
import { NotificationIcon } from './NotificationIcon';
import { useLoginInfo } from '../../react-hooks/useLoginInfo';
import Notifications from './icons/notifications.svg?svgr';

interface Props {
  onMenuClick: () => void;
  onNotificationsClick: (hasUnviewed: boolean) => void;
}

const Controls = ({ onMenuClick, onNotificationsClick }: Props): JSX.Element => {
  const { user } = useLoginInfo();

  const isOnBlogs = Boolean(useMatch(ROUTES.BLOGS.$path()));

  return (
    <div className="flex items-center gap-4.5">
      {isOnBlogs ? (
        <CreatePostButton className="hidden md:inline-flex" variant={ButtonVariant.Outlined} buttonAlwaysVisible />
      ) : null}
      {user ? <NotificationIcon onClick={onNotificationsClick} user={user} /> : <Notifications color="transparent" />}
      <Button to={ROUTES.SEARCH.$buildPath({})} aria-label="Поиск">
        <Magnifier />
      </Button>
      <Button onClick={onMenuClick} aria-label="Меню">
        <Burger />
      </Button>
    </div>
  );
};

export { Controls };
