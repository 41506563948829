import { useLocation } from 'react-router';
import { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';

declare global {
  interface Ya {
    adfoxCode?: AdFoxCode;
  }

  interface AdFoxCode {
    clearSession?: () => void;
  }

  interface Window {
    Ya?: Ya;
  }
}

const AdPageChangeHandler = () => {
  const { pathname } = useLocation();

  const shouldResetRef = useRef(false);

  useEffect(() => {
    if (shouldResetRef.current) {
      window.Ya?.adfoxCode?.clearSession?.();
    }

    shouldResetRef.current = true;
  }, [pathname]);

  return (
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/html-has-lang */}
      <html data-pathname={pathname} />
    </Helmet>
  );
};

export { AdPageChangeHandler };
