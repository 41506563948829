import { ReactNode, useState, useCallback, KeyboardEvent, ChangeEvent, SyntheticEvent, useEffect, useRef } from 'react';
import { useMediaQuery, hoverable } from '@flame-frontend-utils/commons';
import { useMutation } from '@apollo/client';
import { CommentReceiver } from '../CommentReceiver';
import { UniversalComment } from '../../types';
import { CommentDeleteButton } from '../CommentDeleteButton';
import { TimeAgo } from '../../../TimeAgo';
import { CommentAuthor } from '../../Author';
import { tw } from '../../../../styles/tw';
import { AutosizedTextarea } from '../../../AutosizedTextarea';
import { Button } from '../../../Button';
import ArrowIcon from '../../arrow.svg?svgr';
import { UpdateCommentDocument } from './gql/UpdateComment.document';

interface CommentContentProps {
  comment: UniversalComment;
  editing?: boolean;
  onEditComplete?: () => void;
  onReceiverClick?: () => void;
  customHeader?: ReactNode;
  hideHeader?: boolean;
}

interface EditableBodyProps {
  comment: UniversalComment;
  onComplete?: () => void;
}

const CommentContent = ({
  comment,
  editing,
  customHeader,
  hideHeader,
  onReceiverClick,
  onEditComplete,
}: CommentContentProps) => {
  const { replyTo, body } = comment;

  const { author, createdAt } = comment;

  return (
    <div>
      {!hideHeader && (
        <div className="mb-3 flex items-start">
          {customHeader || <CommentAuthor author={author} />}
          <div className="ml-auto grid grid-flow-col gap-2">
            <TimeAgo className="shrink-0 whitespace-nowrap text-sm" time={new Date(createdAt)} />
            <CommentDeleteButton comment={comment} />
          </div>
        </div>
      )}
      <div className="overflow-anywhere whitespace-pre-wrap">
        {replyTo ? <CommentReceiver inline receiver={replyTo} onClick={onReceiverClick} /> : null}
        {editing ? (
          <EditableBody comment={comment} onComplete={onEditComplete} />
        ) : (
          // Sync with EditableBody min height
          <p className="min-h-[1.5rem]">{body}</p>
        )}
      </div>
    </div>
  );
};

const EditableBody = ({ comment, onComplete }: EditableBodyProps) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const [body, setBody] = useState(comment.body);

  const trimmedBody = body.trim();

  const [updateComment, { loading }] = useMutation(UpdateCommentDocument);

  const handleSubmit = useCallback(
    async (e: SyntheticEvent) => {
      e.preventDefault();

      await updateComment({ variables: { id: comment.id, body: trimmedBody } });

      onComplete?.();
    },
    [comment.id, onComplete, trimmedBody, updateComment],
  );

  const isHoverable = useMediaQuery(hoverable, { initialValue: false });

  const canSubmit = trimmedBody.length > 0 && !loading;

  const onKeyDown = useCallback(
    (e: KeyboardEvent<HTMLTextAreaElement>) => {
      if (isHoverable && e.key === 'Enter' && !e.shiftKey) {
        if (canSubmit) {
          void handleSubmit(e);
        } else {
          e.preventDefault();
        }
      }
    },
    [canSubmit, handleSubmit, isHoverable],
  );

  const handleChange = useCallback((e: ChangeEvent<HTMLTextAreaElement>) => {
    setBody(e.target.value);
  }, []);

  useEffect(() => {
    textareaRef.current?.focus();
    textareaRef.current?.setSelectionRange(textareaRef.current.value.length, textareaRef.current.value.length);

    if (textareaRef.current?.parentElement) {
      textareaRef.current.parentElement.scrollTop = textareaRef.current.parentElement.scrollHeight;
    }
  }, []);

  return (
    <form className="flex min-h-[1.5rem] items-start" onSubmit={handleSubmit}>
      <AutosizedTextarea
        className="max-h-36 grow resize-none overflow-auto"
        rows={1}
        placeholder="Изменить комментарий"
        value={body}
        onChange={handleChange}
        onKeyDown={onKeyDown}
        renderTextarea={(props) => (
          <textarea
            {...props}
            ref={textareaRef}
            className={tw(
              props.className,
              'outline-none placeholder:text-xs placeholder:text-half-blood md:placeholder:text-base',
            )}
          />
        )}
      />
      <Button className="ml-4 disabled:hidden" type="submit" disabled={!canSubmit || loading}>
        <ArrowIcon width={24} height={24} />
      </Button>
    </form>
  );
};

export { CommentContent };
