import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { AdvertisementsDocument } from './Advertisements.document';
import { AdvertisementId } from './constants';
import { useServerData } from '../ServerDataProvider';

interface AdvertisementHeadProps {
  adContext?: AdContext;
}

interface AdContext {
  headHtml?: string;
}

const AdvertisementHead = ({ adContext }: AdvertisementHeadProps) => {
  const { DISABLE_ADS } = useServerData();

  const { data: currentData, previousData } = useQuery(AdvertisementsDocument, {
    fetchPolicy: 'cache-first',
    skip: DISABLE_ADS,
  });

  const data = currentData || previousData;

  const headHtml = useMemo(
    () => data?.advertisements.find(({ id: adId }) => adId === AdvertisementId.Head)?.body ?? '',
    [data?.advertisements],
  );

  if (adContext) {
    // eslint-disable-next-line no-param-reassign
    adContext.headHtml = headHtml;
  }

  return null;
};

export { AdvertisementHead };
export type { AdContext };
