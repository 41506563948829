import { useEffect, useState } from 'react';
import { useIsClientSide } from './useIsClientSide';
import { useDerivedState } from './useDerivedState';

interface UseMediaQueryOptions {
  initialValue?: boolean;
  unsafe?: boolean;
}

function useMediaQuery(mediaQuery: string, options: UseMediaQueryOptions & { unsafe: true }): boolean;
function useMediaQuery(mediaQuery: string, options: UseMediaQueryOptions & { initialValue: boolean }): boolean;
function useMediaQuery(mediaQuery: string, options?: UseMediaQueryOptions): boolean | undefined;
function useMediaQuery(mediaQuery: string, { initialValue, unsafe }: UseMediaQueryOptions = {}): boolean | undefined {
  const [matches, setMatches] = useState(
    !unsafe
      ? initialValue
      : typeof window === 'undefined'
        ? undefined
        : window.matchMedia(normalizeMediaQuery(mediaQuery)).matches,
  );
  const isClientSide = useIsClientSide();

  useDerivedState(() => {
    if (isClientSide) {
      setMatches(matchMedia(normalizeMediaQuery(mediaQuery)).matches);
    }
  }, [isClientSide, mediaQuery]);

  useEffect(() => {
    let mediaQueryList: MediaQueryList | null = null;

    if (typeof window !== 'undefined') {
      mediaQueryList = matchMedia(normalizeMediaQuery(mediaQuery));
    }

    const onMediaQueryChange = (event: MediaQueryListEvent) => {
      setMatches(event.matches);
    };

    if (mediaQueryList) {
      setMatches(mediaQueryList.matches);
    }

    // Safari < 14 does not support MQL events
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    mediaQueryList?.addEventListener?.('change', onMediaQueryChange);

    return () => {
      // Safari < 14 does not support MQL events
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      mediaQueryList?.removeEventListener?.('change', onMediaQueryChange);
    };
  }, [mediaQuery]);

  return matches;
}

function normalizeMediaQuery(mediaQuery: string): string {
  return mediaQuery.replace('@media', '');
}

export { useMediaQuery, normalizeMediaQuery };
