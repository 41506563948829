import { useQuery } from '@apollo/client';
import { AdvertisementsDocument } from './Advertisements.document';
import { AdvertisementId } from './constants';

function useRepeats(repeatsId: AdvertisementId): number | null {
  const { data: currentAdvertisementsData, previousData: previousAdvertisementsData } = useQuery(
    AdvertisementsDocument,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const advertisementsData = currentAdvertisementsData || previousAdvertisementsData;

  return advertisementsData
    ? (advertisementsData.advertisements.find(({ id }) => id === repeatsId)?.repeats ?? getDefaultRepeats(repeatsId))
    : null;
}

function useMultipleRepeats(repeatsIds: AdvertisementId[]) {
  const { data: currentAdvertisementsData, previousData: previousAdvertisementsData } = useQuery(
    AdvertisementsDocument,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const advertisementsData = currentAdvertisementsData || previousAdvertisementsData;

  const result: Partial<Record<AdvertisementId, number>> = {};

  if (advertisementsData) {
    repeatsIds.forEach((id) => {
      const advertisement = advertisementsData.advertisements.find((ad) => ad.id === id);

      result[id] = advertisement?.repeats ?? getDefaultRepeats(id);
    });
  }

  return result;
}

function getDefaultRepeats(id: AdvertisementId): number {
  switch (id) {
    case AdvertisementId.DesktopInCommentsBanner:
    case AdvertisementId.MobileInComments:
      return 6;
    case AdvertisementId.DesktopInChildCommentsBanner:
    case AdvertisementId.MobileInChildComments:
      return 4;
    case AdvertisementId.DesktopInContentBanner:
    case AdvertisementId.MobileInContent:
    case AdvertisementId.MobileMainPageInContent:
      return 4;
    default:
      return 1;
  }
}

export { useRepeats, useMultipleRepeats };
