import { route, string, union } from 'react-router-typesafe-routes';
import { POST_MESSAGE_FRAGMENT } from '@flame-frontend-utils/commons-router6';
import { SearchSection, ShowcaseComponent, UserPageContent } from './routeParams';

const ROOT = route({ path: '', children: { LEGACY_PAGE: route({ path: 'page/:legacyPage?' }) } });

const FRAGMENTS = {
  WITH_ID: route({ path: ':id' }),
  WITH_SEARCH: route({ searchParams: { query: string().default('') } }),
};

const ROUTES = {
  ROOT,
  POST: route({
    path: ':section?/:subsection?/:slug',
    searchParams: { parentCommentId: string(), targetCommentId: string() },
    children: { LEGACY_COMMENTS: route({ path: 'comments/:legacyPage?' }) },
  }),
  BLOGS: route({ path: 'blogs' }),
  USER_FILTER: route({ path: 'user/:id', searchParams: { section: union(UserPageContent) } }),
  TAG_FILTER: route({ path: 'tag/:id', children: { LEGACY_PAGE: route({ path: 'page/:legacyPage?' }) } }),
  BLOG_EDIT: route({ path: 'edit/:id', searchParams: { backTo: string() } }),
  LEGACY_TAG: route({ path: 'tag' }),
  SEARCH: route({
    path: 'search',
    compose: [FRAGMENTS.WITH_SEARCH],
    searchParams: { section: union(SearchSection).default(SearchSection.Articles) },
  }),
  ABOUT: route({ path: 'about' }),
  PRIVACY: route({ path: 'privacy' }),
  AGREEMENT: route({ path: 'user-agreement' }),
  MODERATION_RULES: route({ path: 'moderation-rules' }),
  JOB_OPENINGS: route({ path: 'job-openings' }),
  ADVERTISING: route({ path: 'advertising' }),
  COMPONENT_SHOWCASE: route({
    path: 'component-showcase',
    children: {
      COMPONENT: route({
        path: ':component',
        params: { component: union(ShowcaseComponent).default(ShowcaseComponent.Clickable) },
      }),
    },
  }),
  POST_MESSAGE: route({ path: 'post-message', compose: [POST_MESSAGE_FRAGMENT] }),
  RSS: route({ path: 'rss' }),
  LEGACY_RSS: route({ path: ':a/:b?/:c?/:d?/rss' }),
  LEGACY_RSS_FEED: route({ path: 'feed.rss' }),
  LEGACY_RSS_YANDEX: route({ path: 'rss-yandex/main' }),
  LEGACY_RSS_MIRTESEN: route({ path: 'rss-mirtesen/main' }),
  LEGACY_RSS_MAILRU: route({ path: 'rss-mailru/main' }),
  LEGACY_RSS_ANEWS: route({ path: 'rss-anews/main' }),
  NOT_FOUND: route({ path: '*' }),
};

const CMS_ROUTES = {
  CALENDAR: route({ path: 'calendar' }),
  POST: route({ path: 'post/:id' }),
};

export { ROUTES, FRAGMENTS, CMS_ROUTES };
