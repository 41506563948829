import { StoreObject } from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';

export function simpleForwardPagination(keyArgs: false | (string | string[])[], uniqueField = 'id') {
  return {
    keyArgs,
    merge(
      existing:
        | {
            totalCount: number | undefined;
            items: Record<string, unknown>;
            pageInfo?: { after: unknown; first: string };
          }
        | undefined,
      incoming: { totalCount: number | undefined; items: StoreObject[]; pageInfo?: { after: unknown; first: string } },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { readField, args }: { readField: ReadFieldFunction; args: Record<string, any> | null },
    ) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const saveExisting = Boolean(args?.pagination?.after);
      const items = existing && saveExisting ? { ...existing.items } : {};

      incoming.items.forEach((item) => {
        items[readField(uniqueField, item) as string] = item;
      });

      return {
        totalCount: incoming.totalCount,
        pageInfo: incoming.pageInfo,
        items,
      };
    },

    read(
      existing:
        | {
            totalCount: number | undefined;
            items: Record<string, unknown>;
            pageInfo?: { after: unknown; first: string };
          }
        | undefined,
    ) {
      if (existing) {
        return {
          totalCount: existing.totalCount,
          pageInfo: existing.pageInfo,
          items: Object.values(existing.items),
        };
      }

      return undefined;
    },
  };
}
