import { useEffect } from 'react';
import { useUpdatingRef } from './useUpdateingRef';
import { isDefined } from '../lib';

export interface UseTimeoutOptions {
  startedAt?: number;
  repeating?: boolean;
}

export function useTimeout(
  cb: () => unknown,
  timeout: number | null,
  { startedAt, repeating }: UseTimeoutOptions = {},
): void {
  const latestCb = useUpdatingRef(cb);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout> | undefined;

    // TODO: Remove Infinity
    if (isDefined(timeout) && timeout !== Infinity) {
      const onTimeout = () => {
        latestCb.current();
        if (repeating) {
          timeoutId = setTimeout(onTimeout, timeout);
        }
      };

      timeoutId = setTimeout(onTimeout, timeout);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [latestCb, timeout, startedAt, repeating]);
}
