import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, CSSProperties } from 'react';
import { AdvertisementId } from './constants';
import { AdvertisementsDocument } from './Advertisements.document';
import { useReportView } from './useReportView';
import { useAdvertisementDisabled } from './AdvertisementDisabledProvider';
import { tw } from '../../styles/tw';
import { useServerData } from '../ServerDataProvider';

interface AdvertisementProps {
  id: AdvertisementId;
  asAside?: boolean;
  className?: string;
  style?: CSSProperties;
}

const VERSTKA_OBSERVE_CLASS_NAME = 'verstka-observe';

const AdvertisementWrapper = (props: AdvertisementProps) => {
  // eslint-disable-next-line react/destructuring-assignment
  const isDisabled = useAdvertisementDisabled(props.id);
  return !isDisabled ? <Advertisement {...props} /> : null;
};

const Advertisement = ({ id, asAside, className, style }: AdvertisementProps) => {
  const { DISABLE_ADS } = useServerData();

  const { data: currentData, previousData } = useQuery(AdvertisementsDocument, {
    fetchPolicy: 'cache-first',
    skip: DISABLE_ADS,
  });

  const data = currentData || previousData;

  const adHtml = useMemo(
    () => data?.advertisements.find(({ id: adId }) => adId === id)?.body ?? '',
    [data?.advertisements, id],
  );

  const adRef = useRef<HTMLDivElement>(null);

  useReportView(adRef, id);

  useEffect(() => {
    const adElement = adRef.current;

    const nodes: Node[] = [];

    if (adHtml) {
      const template = document.createElement('template');
      template.innerHTML = adHtml;
      const templateNodes = [...template.content.childNodes];

      templateNodes.forEach((templateNode) => {
        if (templateNode.nodeName === 'SCRIPT') {
          const selfScript = document.createElement('script');

          selfScript.innerHTML = (templateNode as HTMLScriptElement).innerHTML;

          Array.from((templateNode as HTMLScriptElement).attributes).forEach((attribute) => {
            selfScript.setAttribute(attribute.nodeName, attribute.nodeValue as string);
          });

          nodes.push(selfScript);
        } else {
          nodes.push(templateNode);
        }
      });
    }

    nodes.forEach((node) => {
      adElement?.appendChild(node);
    });

    return () => {
      nodes.forEach((node) => {
        if (adElement?.contains(node)) {
          adElement.removeChild(node);
        }
      });
    };
  }, [adHtml]);

  if ([AdvertisementId.MobileMainTopBillboard, AdvertisementId.DesktopMainTopBillboard].includes(id)) {
    return null;
  }

  const Tag = asAside ? 'aside' : 'div';

  return (
    <Tag
      className={tw(
        'relative z-ad flex justify-center overflow-hidden',
        fixedSize(id),
        className,
        VERSTKA_OBSERVE_CLASS_NAME,
      )}
      style={style}
      ref={adRef}
    />
  );
};

function fixedSize(id: AdvertisementId): string {
  switch (id) {
    case AdvertisementId.DesktopTopBillboard:
      return tw('min-h-[250px]');
    case AdvertisementId.DesktopInContentBanner:
    case AdvertisementId.DesktopInCommentsBanner:
    case AdvertisementId.DesktopInChildCommentsBanner:
      return tw('aspect-[10/6] w-full');
    case AdvertisementId.DesktopMainPageContentBillboard:
      return tw('h-[300px]');
    case AdvertisementId.MobileTopBillboard:
      return tw('aspect-[375/120] w-full');
    case AdvertisementId.MobileAfterCover:
    case AdvertisementId.MobileInContent:
      return tw('aspect-[4/5] w-full');
    case AdvertisementId.MobileMainPageInContent:
    case AdvertisementId.MobileMainPageAfterCover:
      return tw('aspect-square w-full');
    case AdvertisementId.MobileInComments:
    case AdvertisementId.MobileInChildComments:
      return tw('aspect-[10/9] w-full');
    default:
      return '';
  }
}

export { AdvertisementWrapper as Advertisement };
