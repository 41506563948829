import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { route, string, useTypedSearchParams } from 'react-router-typesafe-routes';

const POST_MESSAGE_FRAGMENT = route({ searchParams: { message: string() } });

interface PostMessagePageProps {
  fallbackTo: string;
}

const PostMessagePage = ({ fallbackTo }: PostMessagePageProps): null => {
  const [{ message }] = useTypedSearchParams(POST_MESSAGE_FRAGMENT);
  const navigate = useNavigate();

  useEffect(() => {
    if (isInIframe()) {
      window.parent.postMessage(message, new URL(window.location.href).origin);
    } else if (window.opener) {
      (window.opener as Window).postMessage(message, new URL(window.location.href).origin);
    } else {
      void navigate(fallbackTo);
    }
  }, [navigate, message, fallbackTo]);

  return null;
};

function isInIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

export { PostMessagePage, POST_MESSAGE_FRAGMENT };
export type { PostMessagePageProps };
