enum InfoType {
  About = 'ABOUT',
  Privacy = 'PRIVACY',
  Advertising = 'ADVERTISING',
  Agreement = 'AGREEMENT',
  ModerationRules = 'MODERATION_RULES',
  JobOpenings = 'JOB_OPENINGS',
}

export { InfoType };
