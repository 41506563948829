import { useCallback } from 'react';
import { useQuery } from '@apollo/client';
import { SidebarHeader } from '../../SidebarHeader';
import { Controls } from './Controls';
import { NotificationsDocument } from './gql/Notifications.document';
import { Loader } from '../../Loader';
import { Notification } from './Notification';
import { MarkAsViewedButton } from './MarkAsViewedButton';
import { useFetchWhenInView } from '../../../react-hooks/useFetchWhenInView';
import { ignoreReject } from '../../../lib/ignoreReject';

interface NotificationsProps {
  onClose: () => void;
  unviewedOnly: boolean;
  setUnviewedOnly: (value: boolean) => void;
}

const PAGE_SIZE = 5;

const NotificationsMenu = ({ onClose, unviewedOnly, setUnviewedOnly }: NotificationsProps) => {
  const { data, loading, fetchMore } = useQuery(NotificationsDocument, {
    variables: { isViewed: unviewedOnly ? false : undefined, first: PAGE_SIZE },
  });
  const onFetchMore = useCallback(() => {
    if (data?.notifications.pageInfo.hasNextPage) {
      void ignoreReject(
        fetchMore({
          variables: { first: PAGE_SIZE, after: data.notifications.pageInfo.endCursor },
        }),
      );
    }
  }, [data?.notifications.pageInfo.endCursor, data?.notifications.pageInfo.hasNextPage, fetchMore]);
  const { intersectionObserverRef } = useFetchWhenInView(onFetchMore);

  const notifications = data?.notifications.edges;
  const withUnviewedNotifications = Boolean(unviewedOnly && notifications?.length);

  return (
    <nav className="flex w-full grow flex-col bg-cream px-7 py-5 md:w-[33.875rem]">
      <SidebarHeader className="mb-14" title="Уведомления" onClose={onClose} />
      <Controls unviewedOnly={unviewedOnly} setUnviewedOnly={setUnviewedOnly} />
      {withUnviewedNotifications ? <MarkAsViewedButton /> : null}
      {notifications ? (
        notifications.length ? (
          notifications.map(({ node }) =>
            // eslint-disable-next-line no-underscore-dangle
            node.__typename === 'CommentAddedNotification' ? (
              <Notification
                key={node.id}
                notificationId={node.id}
                createdAt={new Date(node.createdAt)}
                body={node.comment.body}
                isViewed={node.isViewed}
                comment={node.comment}
                onClose={onClose}
              />
            ) : // eslint-disable-next-line no-underscore-dangle
            node.__typename === 'ModerationRejectedNotification' ? (
              <Notification
                key={node.id}
                notificationId={node.id}
                createdAt={new Date(node.createdAt)}
                body={`Ваш пост "${node.mediaPost.title}" не прошел модерацию, инструкции по исправлению поста отправлены на ваш email`}
                post={node.mediaPost}
                isViewed={node.isViewed}
                onClose={onClose}
              />
            ) : (
              <Notification
                key={node.id}
                notificationId={node.id}
                createdAt={new Date(node.createdAt)}
                body="Неизвестное уведомление"
                isViewed={node.isViewed}
                onClose={onClose}
              />
            ),
          )
        ) : (
          <p className="mt-5 flex justify-center">У вас пока нет уведомлений</p>
        )
      ) : null}
      {loading || data?.notifications.pageInfo.hasNextPage ? (
        <Loader className="flex justify-center pt-6" ref={intersectionObserverRef} />
      ) : null}
    </nav>
  );
};

export { NotificationsMenu };
