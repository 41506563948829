import { Button, ButtonColor } from '../../Button';
import { BottomSection } from './BottomSection';
import { MainSection } from './MainSection';
import Cross from './cross.svg?svgr';

interface Props {
  onClose: () => void;
}

const Menu = ({ onClose }: Props): JSX.Element => (
  <nav className="flex grow flex-col bg-blood px-4 pb-7 pt-11 text-grey md:w-[33.875rem]">
    <Button className="absolute right-7 top-7" color={ButtonColor.Secondary} onClick={onClose}>
      <Cross />
    </Button>
    <MainSection closeMenu={onClose} />
    <BottomSection onClick={onClose} />
  </nav>
);

export { Menu };
