import { Fragment } from 'react';
import { useLocation } from 'react-router';
import { Button, ButtonVariant, ButtonColor } from '../Button';
import { ROUTES } from '../../lib/ROUTES';
import { tw } from '../../styles/tw';

const tags = [
  {
    alias: 'lyubov',
    value: 'Любовь',
  },
  {
    alias: 'dengi',
    value: 'Деньги',
  },
  {
    alias: 'slava-1',
    value: 'Слава',
  },
  {
    alias: 'uspekh',
    value: 'Успех',
  },
  {
    alias: 'udovolstviya',
    value: 'Удовольствия',
  },
  {
    alias: 'neudachi-i-poteri',
    value: 'Неудачи и потери',
  },
];

interface TagMenuProps {
  color?: ButtonColor;
  onClick?: () => void;
}

const TagsMenu = ({ color, onClick }: TagMenuProps): JSX.Element => {
  const location = useLocation();

  const onInnerClick = () => {
    onClick?.();
  };

  return (
    <>
      {tags.map((tag) => {
        const tagPath = ROUTES.TAG_FILTER.$buildPath({ params: { id: tag.alias } });
        const active = tagPath === location.pathname;

        return (
          <Fragment key={tag.alias}>
            <Button
              color={color}
              className={tw(active && 'font-semibold', 'inline')}
              variant={ButtonVariant.Text}
              to={tagPath}
              onClick={onInnerClick}
            >
              {tag.value}
            </Button>
          </Fragment>
        );
      })}
    </>
  );
};

export { TagsMenu };
